import React, { ReactElement, useEffect, useState } from "react";
import { Box, useBoolean } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { AuthModal } from "../../components/auth/AuthModal";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { NPCForm } from "../../components/create_homebrew/npc/NPCForm";
import { uploadNPCPdf } from "../../components/create_homebrew/pdfgeneration/NpcPdf";
import { Break } from "../../components/styled/Break";
import { NPCEntity } from "../../constants/npc/NPC";
import { templateList } from "../../constants/npc/Templates";
import { useApiClient } from "../../context/ApiClient";
import { useAuthState } from "../../context/AuthState";
import { parseUrlParams } from "../../Utils";
import { Hide } from "../../components/common/Hide";
import { COLLECTIONS } from "../../constants/General";
import { getEntity, updateEntity } from "../../api/common";
import { Title } from "../../components/styled/Text";
import { MonsterEntity } from "../../constants/Monster";

export default function NPC(): ReactElement {
  const [title, setTitle] = useState("NPC Generator BETA");
  const [alert, setAlert] = useState(<div />);
  const [showSignUpForm, setShowSignUpForm] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const authState = useAuthState();
  const client = useApiClient().getClient();
  const [referenceId, setReferenceId] = useState("");
  const [NPCEntity, setNPCEntity] = useState(undefined);

  const apiClient = useApiClient().getClient();

  useEffect(() => {
    const paramMap = parseUrlParams(location.search);
    setReferenceId(paramMap.get("referenceId"));
    getEntity(apiClient, COLLECTIONS.NPCs, paramMap.get("referenceId")).then((response) => {
      setNPCEntity(response.data);
      setTitle(response.data.name);
    });
  }, []);

  const onTemplateSelected = (template: string) => {
    const newNPC = NPCEntity!;
    newNPC["template"] = template;
    setNPCEntity(newNPC);
  };

  const onNPCSubmit = async (values: NPCEntity) => {
    if (!authState.loggedIn) {
      setShowSignUpForm.on();
    } else {
      setIsLoading.on();
      const monsterResponse = await getEntity(apiClient, COLLECTIONS.Monsters, values.monsterRefId!);
      const monsterEntity: MonsterEntity = monsterResponse.data;
      await updateEntity(client, referenceId, COLLECTIONS.NPCs, values);
      await uploadNPCPdf(values, monsterEntity, authState, values.fileS3Key);
      navigate("/saved/npc");
    }
  };

  const templateOptions = [""];
  templateList.forEach((t) => templateOptions.push(t.value));

  return (
    <Box backgroundColor="npc.background" minH="100vh">
      {isLoading ? (
        <div />
      ) : (
        <div>
          <MyNavbar />
          {alert}
          <Break height="5vh" />
          <Box bg="white" m="0 5vw 0 5vw" p="0 3vw 0 3vw">
            <Title text={title} fontSize="2.3rem" />
            <Hide hide={NPCEntity === undefined}>
              <NPCForm setTitle={setTitle} setAlert={setAlert} onSubmit={onNPCSubmit} npcEntity={NPCEntity} editing={true} />
            </Hide>
          </Box>
          <AuthModal isOpen={showSignUpForm} onClose={setShowSignUpForm.off} startSignUp={true} />
          <Break height="5vh" />
        </div>
      )}
    </Box>
  );
}
